import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductPrice } from 'src/app/models/product-price';
import { StoreService } from 'src/app/services/store.service';
import { ToastService } from 'src/app/services/toast.service';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css'],
})
export class ProductosComponent implements OnInit {
  @ViewChild('table') table!: AdminTableComponent<ProductPrice>;
  @ViewChild('uploadModal') uploadModal!: ElementRef;
  @ViewChild('confirmModal') confirmModal!: ElementRef;

  file: File | null = null;
  fileName: string = '';
  fileError: boolean = false;

  mapHeader = {
    id: { display: '#', data: null },
    plant__code: { display: 'Código', data: null },
    plant__name: { display: 'Planta', data: null },
    product: { display: 'Producto', data: null },
    price: { display: 'Precio', data: null },
    user__name: { display: 'Usuario', data: null },
  };

  data$ = 'product-price/';

  constructor(
    private modalService: NgbModal,
    private storeService: StoreService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  openUploadModal(): void {
    this.modalService.open(this.uploadModal, { centered: true });
  }

  openConfirmModal(): void {
    if (this.file) {
      this.modalService.open(this.confirmModal, { centered: true });
    } else {
      this.toastService.show('Error', 'Debe seleccionar un archivo', {
        classname: 'bg-danger text-light',
      });
    }
  }

  triggerFileInput(): void {
    document.getElementById('customFile')?.click();
  }

  onFileChange(event: any): void {
    const selectedFile = event.target.files[0];
    const allowedExtensions = /(\.xls|\.xlsx|\.xlsm)$/i;

    if (selectedFile) {
      if (!allowedExtensions.exec(selectedFile.name)) {
        this.fileError = true;
        this.fileName = '';
        this.file = null;
        event.target.value = '';
      } else {
        this.fileError = false;
        this.file = selectedFile;
        this.fileName = selectedFile.name;
      }
    }
  }

  uploadFile(): void {
    if (this.file) {
      const user = localStorage.getItem('user');
      const userId = user ? JSON.parse(user).id : null;

      if (!userId) {
        this.toastService.show('Error', 'No se pudo identificar al usuario', {
          classname: 'bg-danger text-light',
        });
        return;
      }

      this.spinner.show();
      this.storeService.uploadFile(this.file, userId).subscribe({
        next: () => {
          this.toastService.show('Éxito', 'Archivo cargado correctamente', {
            classname: 'bg-success text-light toast-top-right', delay: 3000
          });
          this.modalService.dismissAll();
          this.reloadTable();
          this.spinner.hide();
        },
        error: () => {
          this.toastService.show('Error', 'Error al cargar el archivo', {
            classname: 'bg-danger text-light toast-top-right', delay: 4000
          });
          this.spinner.hide();
        },
      });
    } else {
      this.toastService.show('Error', 'Debe seleccionar un archivo', {
        classname: 'bg-danger text-light',
      });
    }
  }

  reloadTable(): void {
    if (this.table) {
      this.table.reload();
    }
  }
}
