import { SapComponent } from './pages/admin/sap/sap.component';
import { RegionComponent } from './pages/admin/region/region.component';
import { ComunaComponent } from './pages/admin/comuna/comuna.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { HomeComponent } from './pages/front/home/home.component';
import { HorarioComponent } from './pages/front/horario/horario.component';
import { MapaComponent } from './pages/front/mapa/mapa.component';
import { PagoComponent } from './pages/front/pago/pago.component';
import { PedidoComponent } from './pages/front/pedido/pedido.component';
import { ProductoComponent } from './pages/front/producto/producto.component';
import { HeaderComponent } from './shared/header/header.component';
import { AdminNavComponent } from './shared/admin-nav/admin-nav.component';
import { ScheduleComponent } from './pages/admin/schedule/schedule.component';
import { CotizacionComponent } from './pages/admin/cotizacion/cotizacion.component';
import { SalesComponent } from './pages/admin/sales/sales.component';
import { ContactsComponent } from './pages/admin/contacts/contacts.component';
import { SettingsComponent } from './pages/admin/settings/settings.component';
import { ShiftComponent } from './pages/admin/shift/shift.component';
import { PlantsComponent } from './pages/admin/plants/plants.component';
import { ExecutiveComponent } from './pages/admin/executive/executive.component';
import { LoginComponent } from './pages/admin/login/login.component';
import { AuthGuard } from './shared/utils/auth.guard';
import { ComunaPlantaComponent } from './pages/admin/comuna-planta/comuna-planta.component';
import { UserComponent } from './pages/admin/user/user.component';
import { ProductosComponent } from './pages/admin/productos/productos.component';
import { ProductPriceLogsComponent } from './pages/admin/product-price-logs/product-price-logs.component';

const numberRegex = '^ROD[0-9]*$';
const regexMatcher = (url: UrlSegment[]) => {
  return url[0]?.path === 'pedido' && url[1].path.match(numberRegex)
    ? {
      consumed: url,
      posParams: {
        id: new UrlSegment(url[1].path.substring(1), {})
      }
    }
    : null;
};

const routes: Routes = [
  {
    path: 'admin',
    component: AdminNavComponent,
    children: [
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'horario',
        component: ScheduleComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'cotizacion',
        component: CotizacionComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'sales',
        component: SalesComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'contacts',
        component: ContactsComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'settings',
        component: SettingsComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'shifts',
        component: ShiftComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'plants',
        component: PlantsComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'executives',
        component: ExecutiveComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'comuna',
        component: ComunaComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'region',
        component: RegionComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'sap',
        component: SapComponent
      },
      {
        data: { isAuthenticate: false },
        path: 'login',
        component: LoginComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'comuna_plant',
        component: ComunaPlantaComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'user',
        component: UserComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'products',
        component: ProductosComponent
      },
      {
        data: { isAuthenticate: true },
        canActivate: [AuthGuard],
        path: 'products-logs',
        component: ProductPriceLogsComponent
      },
      {
        data: { isAuthenticate: false },
        path: '**', pathMatch: 'full', redirectTo: 'plants'
      }
    ]
  },
  {
    path: '',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'horario',
        component: HorarioComponent
      },
      {
        path: 'mapa',
        component: MapaComponent
      },
      {
        path: 'pago',
        component: PagoComponent
      },
      {
        path: 'pago/:id',
        component: PagoComponent
      },
      {
        path: 'pago/confirmacion/:orderId',
        component: PagoComponent,
        data: { isConfirmation: true } 
      },
      {
        path: 'pago/cancelar/:orderId',
        component: PagoComponent,
        data: { isConfirmation: false } 
      },
      {
        matcher: regexMatcher,
        component: PedidoComponent
      },
      {
        path: 'pedido/:id',
        component: PedidoComponent
      },
      {
        path: 'producto',
        component: ProductoComponent
      },
      { path: '**', pathMatch: 'full', redirectTo: '' }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
