<div class="px-5">
  <h1>Productos</h1>
  <div class="d-flex justify-content-end mb-3">
    <button class="btn btn-primary" (click)="openUploadModal()">
      Subir Archivo
    </button>
  </div>
  <app-admin-table
    #table
    [mapHeader]="mapHeader"
    [queryset]="data$"
  ></app-admin-table>
</div>

<!-- Modal de Carga -->
<ng-template #uploadModal>
  <div class="modal-header">
    <h4 class="modal-title">Subir Archivo Excel</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Cerrar"
      (click)="modalService.dismissAll()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="customFile" class="form-label"
        >Seleccione un archivo Excel</label
      >
      <div class="input-group">
        <!-- Input oculto -->
        <input
          type="file"
          id="customFile"
          class="d-none"
          (change)="onFileChange($event)"
          accept=".xls,.xlsx,.xlsm"
        />
        <!-- Input personalizado -->
        <input
          type="text"
          class="form-control"
          [value]="fileName || 'Ningún archivo seleccionado'"
          readonly
          (click)="triggerFileInput()"
        />
        <button class="btn btn-secondary" (click)="triggerFileInput()">
          Buscar
        </button>
      </div>
    </div>

    <div *ngIf="fileError" class="text-danger mt-2">
      Solo se permiten archivos de Excel (.xls, .xlsx, .xlsm).
    </div>
    <!-- Enlace para descargar el archivo modelo -->
    <div class="mt-3">
      <a
        href="assets/archivos/modelo-productos.xlsm"
        download="modelo-productos.xlsm"
        class="minimal-link"
      >
        Descargar archivo modelo
      </a>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" (click)="openConfirmModal()">Subir</button>
  </div>
</ng-template>

<!-- Modal de Confirmación -->
<ng-template #confirmModal>
  <div class="modal-header">
    <h4 class="modal-title">Confirmación</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Cerrar"
      (click)="modalService.dismissAll()"
    ></button>
  </div>
  <div class="modal-body">
    ¿Está seguro de que desea subir el archivo <b>{{ fileName }}</b
    >?
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="modalService.dismissAll()">
      Cancelar
    </button>
    <button class="btn btn-success" (click)="uploadFile()">Confirmar</button>
  </div>
</ng-template>
