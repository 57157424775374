<div class="col" [class]="responsiveService.styleResponsive('px-2', 'px-5') | async">
  <div class="bg-container-b container-fluid p-0 mb-4">
    <div class="col-12 pt-3" [class]="responsiveService.styleResponsive('d-block', 'd-flex') | async">
      <div class="col offset-1 px-0 py-2">
        <div class="col-10 color-primary mb-4">
          <h6>Producto</h6>
          <h3>{{ order?.product?.name_comercial }}</h3>
        </div>
        <div class="col-10 color-primary mb-4">
          <h6>Cantidad</h6>
          <h3>{{ order?.product?.amount }} m³
            <img class="ms-1" *ngFor="let item of countTruck(order?.product?.amount ?? 0)" src="assets/img/truck.svg"
              alt="truck" [height]="25" [width]="30">
          </h3>
        </div>
        <div class="col-10 color-secondary mb-4">
          <h6>Monto a pagar</h6>
          <h1>{{ order?.schedule?.price | currency:'$ ':'symbol':'.0':'es' }}</h1>
        </div>
        <div class="col-10 color-primary mb-4">
          <h6>Dirección de entrega</h6>
          <h4>{{ order?.map?.address }}</h4>
        </div>
        <div class="col-10 color-primary mb-3">
          <h6>Fecha de entrega</h6>
          <h4>{{ order?.schedule?.date }}</h4>
          <h6>{{ order?.schedule?.schedule_range }}</h6>
        </div>
      </div>
      <div class="col px-3">
        <form autocomplete="off" [formGroup]="form" (ngSubmit)="guardar()">
          <input type="text" placeholder="Correo Electrónico *" [style.border]="'1.5px solid lightgray'"
            [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="email"
            [readonly]="order?.cotizacion" [class.is-invalid]="form.get('email')?.touched && form.get('email')?.invalid"
            aria-describedby="emailValidation" />
          <div id="emailValidation" class="invalid-feedback">
            Por favor ingrese un correo válido
          </div>

          <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
            class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="name"
            [readonly]="order?.cotizacion" [class.is-invalid]="form.get('name')?.touched && form.get('name')?.invalid"
            aria-describedby="nameValidation" />

          <input type="text" placeholder="RUT a Facturar *" [style.border]="'1.5px solid lightgray'"
            [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="rut"
            [readonly]="order?.cotizacion" (change)="checkRUT()"
            [class.is-invalid]="form.get('rut')?.touched && form.get('rut')?.invalid"
            aria-describedby="rutValidation" />
          <div id="rutValidation" class="invalid-feedback">
            Por favor ingrese un RUT válido
          </div>

          <input type="text" placeholder="Nombre de Obra *" [style.border]="'1.5px solid lightgray'"
            [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="giro"
            [readonly]="order?.cotizacion" [class.is-invalid]="form.get('giro')?.touched && form.get('giro')?.invalid"
            aria-describedby="giroValidation" />


          @if(!order?.cotizacion){

          <div class="d-flex justify-content-start my-3">

            <span for="mismadireccion" class="btn-link no-underline pe-3" [style.cursor]="'pointer'">Utilizar dirección de despacho como dirección de facturación</span>
            <input (click)="modificarDireccion()" type="checkbox" id="mismadireccion" formControlName="mismadireccion"
              class="form-check-input">
            <label class="form-check-label" for="mismadireccion"></label>
          </div>
          }

          <input type="text" placeholder="Dirección de Facturación *" [style.border]="'1.5px solid lightgray'"
            [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="helper"
            [readonly]="true" autocorrect="off" autocapitalize="off" spellcheck="off"
            [class.is-invalid]="form.get('helper')?.touched && form.get('helper')?.invalid"
            (keyup.enter)="$event.preventDefault();" aria-describedby="helperValidation"
            [ngStyle]="{'background-color': order?.cotizacion ? '#e9ecef' : '#fff'}"
            (click)="order?.cotizacion || usarMismaDireccion ? null : openModal(addressModal)" #selectMap />

          <input type="text" placeholder="Teléfono *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
            class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="phone"
            [readonly]="order?.cotizacion" [class.is-invalid]="form.get('phone')?.touched && form.get('phone')?.invalid"
            aria-describedby="phoneValidation" />

          <div class="d-flex justify-content-center form-check my-3">
            <input type="checkbox" id="accept" formControlName="check" [readonly]="order?.cotizacion"
              class="form-check-input" [class.is-invalid]="form.get('check')?.touched && form.get('check')?.invalid">
            <label class="form-check-label" for="accept"></label>
            <span for="check" (click)="openModal(content)" class="btn-link no-underline ps-3"
              [style.cursor]="'pointer'">Acepto los
              términos y condiciones</span>
          </div>

          <div class="clearfix w-100 my-auto text-center">
            <button class="w-50 btn btn-cancel  p-2 px-auto text-uppercase text-truncate" type="button"
              [style.border]="'1.5px solid lightgray'" [style.font-size.px]="20" (click)="cotizacion()"
              *ngIf="!order?.cotizacion">{{responsiveService.styleResponsive('cotizar', 'guardar
              cotización') | async}}
            </button>
            <button class="w-50 btn btn-secondary  p-2 px-auto text-uppercase" [style.border]="'1.5px solid lightgray'"
              [style.font-size.px]="20">continuar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<form action="{{ transaction.url }}" method="POST" #transactionFrom>
  <input name="token_ws" hidden value="{{ transaction.token }}">
</form>

<ng-template #content let-c="close" let-d="dismiss" class=" w-50">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">
      <img class="me-4" src="assets/img/logo.svg" alt="Cbb Store" [width]="50">
      Términos y Condiciones
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>
      Las facturas por venta de hormigón son emitidas por Cementos Bío Bío S.A.
    </p>
    <p>
      Será de responsabilidad y costo del cliente el acondicionamiento de los caminos interiores de la faena, considerando que el ancho mínimo de acceso a la obra debe ser de 5,0 m. y una altura libre de 4,2 m., los que deben estar iluminados y despejados para una operación segura y adecuada. Considerar que camión no debe entrar retrocediendo mas de 20 metros
    </p>
    <p>
      Será de responsabilidad y costo del cliente la obtención de autorización de pases de ingreso y los permisos de descarga en el exterior de la obra.
    </p>
    <p>
      Para una operación sin accidentes se recomienda consultar la "Guía para evitar accidentes en la descarga de hormigón".
    </p>
    <p>
      La entrega de hormigón se efectuará en camiones mixer con capacidad máxima de 7,5 m3. El volumen mínimo a transportar es de 2 m3.
    </p>
    <p>
      Se entenderá por estadía de camión en obra, al tiempo transcurrido entre la llegada del camión a la obra y su salida desde la misma, la cual no podrá exceder los 8 minutos por cada M3 a entregar
    </p>
    <p>
      Es responsabilidad y costo del cliente el hormigón despachado de acuerdo a la programación y que por problemas atribuibles a la obra no sean recibidos. De igual manera aquellos viajes solicitados y que no sean descargados en su totalidad. El cliente deberá descargar la totalidad del hormigón solicitado,en caso contrario, el hormigón sobrante deberá ser descargado en el lugar habilitado para ello por el cliente dentro de la misma obra. 
    </p>
    <p>
      Ready Mix se reserva el derecho de suspender temporal o definitivamente el servicio, en caso de contingencias de fuerza mayor, caso fortuito u otras causas que afecten la calidad de los hormigones y la seguridad en las condiciones de entrega.
    </p>
    <p>
      Ready Mix no será responsable por fenómenos no deseados en el hormigón, producto de condiciones particulares de la obra que no hayan sido informada formalmente por el cliente, tales como Especificaciones Técnicas particulares o generales, solicitudes de la ITO, del Proyectista u otras, y/o producto de condiciones especiales de obra, tales como: elementos masivos, temperaturas máximas o mínimas de los hormigones, adiciones en obra de algún producto en particular, colocación (altura de caída, inclinación de canoas, reducciones de tuberías), condiciones de servicio durante su vida útil, condiciones climáticas (temperaturas, evaporación), u otros que se aparten de las condiciones normales, corrientes y de buena práctica.
    </p>
    <p>
      Las normas de referencia para el diseño, confección, transporte y evaluación de hormigones, son NCh170, NCh1934, NCh1989, en sus aspectos normales, corrientes y considerados de buena práctica.
    </p>
    <p>
      De acuerdo a la normativa  vigente (NCh170 2016),  los únicos productos aceptados para hormigón armado son los grados G17 y superiores, con fracción defectuosa de 10%, por lo cual están dosificados considerando una dosis mínima de cemento de 240 kg/m3. En caso de requerir otros valores de dosis mínima y/o requisitos por durabilidad (ver NCh170), será responsabilidad del cliente indicarlo formalmente para realizar los ajustes técnicos, operacionales y comerciales que sean necesarios. 
    </p>
    <p>
      El control de volúmenes y rendimientos se debe realizar bajo parámetros establecidos en la norma NCh1934.
    </p>
    <p>
      Las condiciones de muestreo de los productos suministrados por Ready Mix, consideran utilización de probeta cilíndrica, compactación de muestras con vibrador de inmersión, curado bajo agua en obra y curado bajo agua en laboratorio. 
    </p>
    <p>Para el análisis de resultados y toma de asentamiento de cono, sólo se considerarán las muestras tomadas dentro de los 30 minutos desde la llegada del camión mixer a la obra, excluyendo el 10% inicial y final de la carga total.</p>
    <p>Los resultados de ensayos de resistencia se evalúan según el método de media móvil de tres muestras consecutivas, de acuerdo con el procedimiento establecido en la Norma Chilena de evaluación NCh1998.</p>
    <p>Ready Mix no será responsable de controles de resultados provenientes de testigos de hormigón endurecido, dado que ellos están afectados por las condiciones de colocación, compactación y curado, las cuales no son administradas por personal de Ready Mix.</p>
    <p>Ready Mix no responderá por eventuales daños o perjuicios  derivados del incumplimiento total o parcial de sus  obligaciones cuando aquello sea consecuencia de eventos de caso fortuito o fuerza mayor, según lo dispuesto en el artículo 45 del Código Civil, tales como, y sin que la enumeración a continuanción sea taxativa, terremotos, inundaciones u otro desastre natural, actos de autoridad, manifestaciones, huelgas y paralizaciones en general, pudiendo incluso suspender la entrega del producto hasta la finalización del evento de fuerza mayor o caso fortuito.  </p>
  </div>
</ng-template>

<ng-template #alertModal let-modal>
  <div class="modal-body alert-background">
    <div style="margin-left: 391px;margin-top: 210px;">
      <h3 style="color: darkblue;">
        Estimados Clientes:
      </h3>
      <h5>
        Para los despachos en comunas bajo <strong>CUARENTENA</strong>, se debe contar con el permiso
        correspondiente
        que lo califique como <strong>Empresa Esencial</strong>
        o la resolución de algún organismo del estado como mandante <strong>(SERVIU, MOP, DOH o Empresa
          Minera)</strong>, los permisos municipales, no son válidos para esta gestión.
      </h5>
      <h5>
        Este documento es indispensable para que podamos realizar el despacho y será solicitado antes de la
        fecha programada.
      </h5>

      <div style="margin-top: 90px;" class="col-11">
        <button (click)="continuarPago();" class="btn btn-primary pull-right"
          style="float: right;	background: #0a1a72; border-color: #0a1a72;	font-size: larger;">
          Continuar al Pago
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addressModal let-modal>
  <div class="modal-body">
    <div class="py-3 m-auto d-block" [formGroup]="formAddress"
      [class]="responsiveService.styleResponsive('row px-0', 'col px-5') | async">
      <h2>Ingrese la ubicación de Facturación</h2>
      <div class="d-flex flex-nowrap">
        <select class="form-control m-2" formControlName="region"
          [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async">
          <option value="" selected disabled>Región</option>
          <option value="{{item.nombreRegion}}" *ngFor="let item of regionesComunas">
            {{item.nombreRegion}}</option>
        </select>

        <select class="form-control m-2" formControlName="comuna"
          [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async">
          <option value="" selected disabled>Comuna</option>
          <option value="{{item}}" *ngFor="let item of comunaByRegion">{{item}}</option>
        </select>
      </div>

      <div class="d-flex flex-nowrap">
        <input [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async" class="form-control m-2"
          type="text" placeholder="Calle" formControlName="street">
        <input [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async" class="form-control m-2"
          type="text" placeholder="Número" formControlName="number">
      </div>
      <button class="btn btn-secondary float-end px-5 py-3" [style.font-size.px]="18" [disabled]="formAddress.invalid"
        (click)="guardarAddress()">Verificar Dirección</button>
    </div>
  </div>
</ng-template>

<ng-template #paymentModal let-modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">
      <img class="me-4" src="assets/img/logo.svg" alt="Cbb Store" [width]="50">
      Seleccione medio de pago
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body d-flex p-5">
    <div class="col text-center row recomendado">
      <a (click)="gnet()">
        <img src="assets/img/logos/getnet-logo.png" alt="gnet-button" class="pago-button pago-button-gold img-thumbnail">
      </a>
    </div>
    <div class="col text-center">
      <a (click)="flow()">
        <img src="assets/img/logos/flow.png" alt="flow-button" class="pago-button img-fluid">
      </a>
    </div>
  </div>
</ng-template>
