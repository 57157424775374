<div class="col px-5">
  <div class="bg-container-b container-fluid p-0 mb-4">
    <div class="row">
      <div
        class="col-12 col-md-6"
        [class]="responsiveService.styleResponsive('row w-100', 'col') | async"
      >
        <google-map
          [height]="'600px'"
          [width]="'inherit'"
          [center]="center"
          [zoom]="zoom"
          [options]="mapOptions"
        >
          <!-- Marcador de la dirección principal -->
          <map-marker
            [position]="{ lat: lat, lng: lng }"
            [options]="{ draggable: false }"
          >
          </map-marker>

          <!-- Marcador de la dirección complementaria -->
          <map-marker
            *ngIf="blueMarkerVisible"
            [position]="{
              lat: complementaryAddressData.lat,
              lng: complementaryAddressData.lng
            }"
            [options]="{
              draggable: true,
              icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
            }"
            (mapDragend)="onComplementaryMarkerDragEnd($event)"
          >
          </map-marker>
        </google-map>
      </div>

      <div
        class="col-12 col-md-6 py-3 m-auto d-block"
        [formGroup]="form"
        [class]="
          responsiveService.styleResponsive('row px-0', 'col px-5') | async
        "
      >
        <h2>Ingrese la ubicación de despacho</h2>
        <h5></h5>
        <div class="d-flex flex-nowrap">
          <select
            class="form-control m-2"
            formControlName="region"
            [style.font-size.px]="
              responsiveService.styleResponsive('16', '26') | async
            "
          >
            <option value="" selected disabled>Región</option>
            <option
              value="{{ item.nombreRegion }}"
              *ngFor="let item of regionesComunas"
            >
              {{ item.nombreRegion }}
            </option>
          </select>

          <select
            class="form-control m-2"
            formControlName="comuna"
            [style.font-size.px]="
              responsiveService.styleResponsive('16', '26') | async
            "
          >
            <option value="" selected disabled>Comuna</option>
            <option value="{{ item }}" *ngFor="let item of comunaByRegion">
              {{ item }}
            </option>
          </select>
        </div>

        <div class="d-flex flex-nowrap">
          <input
            [style.font-size.px]="
              responsiveService.styleResponsive('16', '26') | async
            "
            class="form-control m-2"
            type="text"
            placeholder="Calle"
            formControlName="street"
          />
          <input
            [style.font-size.px]="
              responsiveService.styleResponsive('16', '26') | async
            "
            class="form-control m-2"
            type="text"
            placeholder="Número"
            formControlName="number"
          />
        </div>
        <label
          for="street"
          class="text-danger"
          *ngIf="form.hasError('noValid')"
        >
          Dirección no disponible
        </label>
        <label
          for="street"
          class="text-danger"
          *ngIf="form.hasError('queryLimit')"
        >
          Demasiados intentos de obtener la dirección, intente más tarde.
        </label>

      <div
        class="col-12 d-block"
      >
        <!-- Botón de Verificar Dirección -->
        <div class="col-12 p-3 m-auto d-block" style="height: 70px"  *ngIf="!enabledSave">
          <button
           
            class="btn btn-secondary float-end px-5 py-3"
            [style.font-size.px]="18"
            (click)="verificar()"
          >
            Verificar Dirección
          </button>
        </div>

        <div class="col-12 py-3 m-auto d-block" style="margin-top: 30px;" *ngIf="blueMarkerVisible">
          <div class="d-flex align-items-center">
            <h2 class="mb-0 me-2">Punto de Acceso</h2>
            <p class=" mb-0" [ngClass]="{'text-danger fw-bold': !optional, 'text-muted': optional}">({{optional ? 'Opcional': 'Obligatorio'}})</p>
          </div>
          <p>
            Arrastre el pin azul en el mapa para indicar el punto de acceso a la obra:
          </p>
          <!-- Dirección completa -->
          <div class="form-group d-flex flex-nowrap">
            <input
              [style.font-size.px]="
                responsiveService.styleResponsive('16', '26') | async
              "
              class="form-control m-2 disable"
              id="complementaryAddress"
              placeholder="Dirección"
              type="text"
              [value]="complementaryAddress"
              readonly
            />
          </div>
          <!-- Calle y Número en una sola línea -->
          <div class="form-group d-flex flex-nowrap">
              <input
                [style.font-size.px]="
                  responsiveService.styleResponsive('16', '26') | async
                "
                class="form-control m-2 disable"
                id="complementaryStreet"
                placeholder="Calle"
                type="text"
                [value]="complementaryAddressData.street"
                readonly
              />
              <input
                [style.font-size.px]="
                  responsiveService.styleResponsive('16', '26') | async
                "
                class="form-control m-2 disable"
                id="complementaryNumber"
                placeholder="Número"
                type="text"
                [value]="complementaryAddressData.number"
                readonly
              />
          </div>
        </div>
        </div>

        <!-- Botón de Continuar -->
        <button
          *ngIf="enabledSave"
          class="btn btn-primary float-end px-5 py-3"
          [style.font-size.px]="18"
          (click)="guardar()"
        >
          CONTINUAR
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #contentUser let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Datos de Contacto</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <form autocomplete="off" [formGroup]="formUser" (ngSubmit)="saveUser()">
    <div class="modal-body">
      <input
        type="text"
        placeholder="Nombre *"
        [style.border]="'1.5px solid lightgray'"
        [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0"
        formControlName="name"
        [class.is-invalid]="
          formUser.get('name')?.touched && formUser.get('name')?.invalid
        "
        aria-describedby="nameValidation"
      />

      <input
        type="text"
        placeholder="Email *"
        [style.border]="'1.5px solid lightgray'"
        [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0"
        formControlName="email"
        [class.is-invalid]="
          formUser.get('email')?.touched && formUser.get('email')?.invalid
        "
        aria-describedby="emailValidation"
      />

      <input
        type="text"
        placeholder="RUT a Facturar *"
        [style.border]="'1.5px solid lightgray'"
        [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0"
        formControlName="rut"
        [class.is-invalid]="
          formUser.get('rut').touched && formUser.get('rut').invalid
        "
        (change)="checkRUT()"
        aria-describedby="rutValidation"
      />
      <div id="rutValidation" class="invalid-feedback">
        Por favor ingrese un RUT válido
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="btn btn-success text-uppercase"
        [disabled]="!ngUserValid"
      >
        Guardar
      </button>
    </div>
  </form>
</ng-template>
