import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductPriceLog } from 'src/app/models/product-price';
import { StoreService } from 'src/app/services/store.service';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';

@Component({
  selector: 'app-product-price-logs',
  templateUrl: './product-price-logs.component.html',
  styleUrls: ['./product-price-logs.component.css'],
})
export class ProductPriceLogsComponent implements OnInit {
  @ViewChild('table') table!: AdminTableComponent<ProductPriceLog>;

  mapHeader = {
    id: { display: '#', data: null },
    plant__code: { display: 'Código Planta', data: null },
    plant__name: { display: 'Planta', data: null },
    product__name: { display: 'Producto', data: null },
    old_price: { display: 'Precio Anterior', data: null },
    old_price_date: { display: 'Fecha del Precio Anterior', data: null },
    new_price: { display: 'Precio Nuevo', data: null },
    updated_at: { display: 'Fecha de actualización', data: null },
    user__name: { display: 'Usuario', data: null },

  };

  data$ = 'product_price_logs/';

  constructor(private storeService: StoreService) {}

  ngOnInit(): void {}
}
